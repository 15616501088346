import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

const FooterLinks = () => {
  return (
    <>
      <Container
        maxWidth={"lg"}
        sx={{ borderTop: "1px solid #ddd", marginTop: "3rem" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              md: "space-between",
              alignItems: "center",
              width: "100%",
            },
          }}
        >
          <Grid container direction="row" py={{ xs: 2, xl: 5 }}>
            <Grid
              item
              container
              lg
              id="details"
              alignItems="start"
              direction="column"
              rowSpacing={{ xs: 1, lg: 2 }}
              py={{ xs: 2, xl: 5 }}
            >
              <Grid item>
                <b>Support</b>
              </Grid>
              <Grid item>Help Center</Grid>
              <Grid item>Safety information</Grid>
              <Grid item>Supporting people with disabilities</Grid>
            </Grid>

            <Grid
              item
              container
              lg
              id="details"
              alignItems="start"
              direction="column"
              rowSpacing={{ xs: 1, lg: 2 }}
              py={{ xs: 2, xl: 5 }}
            >
              <Grid item>
                <b>Community</b>
              </Grid>

              <Grid item>Cancellation options</Grid>
              <Grid item>Report a neighborhood concern</Grid>
              <Grid item>Combating discrimination</Grid>
            </Grid>

            <Grid
              item
              container
              lg
              id="details"
              alignItems="start"
              direction="column"
              rowSpacing={{ xs: 1, lg: 2 }}
              py={{ xs: 2, xl: 5 }}
            >
              <b>Hosting</b>
              <Grid item>Try hosting</Grid>
              <Grid item>AirCover for Hosts</Grid>
              <Grid item>Explore hosting resources</Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default FooterLinks;
